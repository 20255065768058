import { useState, useEffect } from 'react';
import './Rest_Password.scss'
import Lock from '../../Static/images/Lock.webp'
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { usePasswordValidation } from '../../helpers/usePasswordValidation';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import SmPreLoader from '../PreLoader/SmPreLoader';
import useCount from '../../helpers/useCountDownTimer';

function Rest_Password() {
    const { phone } = useParams();
    const [password_confirmation, setPassword_confirmation] = useState("");
    const [Message, setMessage] = useState();
    const { password, setPassword, passErrors } = usePasswordValidation();
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const [t] = useTranslation();
    const [lod, setLod] = useState(false); // loading state
    const { secondsLeft, start, formatTime } = useCount();

    const handleResetPassword = async (event) => {
        try {
            setMessage('');
            event.preventDefault();
            setLod(true);
            if (passErrors.length === 0) {
                if (password === password_confirmation) {
                    const reset_response = await axios.post('reset', { otp, password });
                    if (reset_response.data === "Done") {
                        setTimeout(() => {
                            navigate('/login', { replace: true });
                        }, 1000);
                    }
                    else if (reset_response.data === "OTP Expired ") {
                        setMessage(t('ResetPassword.OTP_Expired'));
                    }
                }
                else {
                    setMessage(t('ResetPassword.PassNotMatch'));
                }
            }

        }
        catch (e) {
            if (e.response.status === 422) {
                setMessage(t('ResetPassword.passwordSetFailed'));
            }
            else {
                console.log(e);
            }
        }
        finally {
            setPassword('');
            setPassword_confirmation('');
            setLod(false);
        }
    }


    const handleResend = async () => {
        try {
            setLod(true);
            const resendOTP_response = await axios.post('reforget', { phone });
        }
        catch (e) {
            console.log(e);
        }
        finally {
            start(60);
            setLod(false);
        }
    }

    useEffect(() => {
        start(60); // Assuming 90 is the initial value for your countdown
    }, []);

    return (
        <>
            {lod && <SmPreLoader />}
            <section className="Rest_Password">
                <div className='LoginDiv1'>
                    <p className="navbar-brand" href="#">Forga<span className='w2EDiff'>Pay</span></p>
                    <div>
                        <h4>
                            {t('ResetPassword.priority')}<br />
                            <img src={Lock} alt="..." />
                        </h4>
                    </div>
                </div>

                <div className='LoginDiv2'>
                    <div className='LoginDiv2Child1'>
                        <div className='LoginDiv2Child1DV1'>
                            <h1>{t('ResetPassword.ResetPasswordHeader')}</h1>
                            <form onSubmit={handleResetPassword}>
                                <div className="form-group">
                                    <div className='otp-Title_resend-Btn'>
                                        <label htmlFor="exampleInputEmail1">{t('ResetPassword.OTP')}</label>

                                        {secondsLeft === 0 ?
                                            <p className='resendBtn' onClick={handleResend}>{t('ResetPassword.ResendOTP')}</p>
                                            :
                                            <span className='count-down'>{t('Verify.resendTime')} ({formatTime(secondsLeft)})</span>
                                        }
                                    </div>
                                    <OtpInput
                                        value={otp.toString()}
                                        onChange={setOtp}
                                        numInputs={6}
                                        placeholder='000000'
                                        containerStyle='OtpContainerCustomStyle'
                                        inputStyle='inputCustomStyle'
                                        skipDefaultStyles={true}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    <label htmlFor="exampleInputEmail1">{t('ResetPassword.newPassword')}</label>
                                    <input type="password"
                                        className="form-control"
                                        id="newPassword"
                                        value={password}
                                        required
                                        onChange={(e) => setPassword(e.target.value)}
                                        onFocus={() => setIsPasswordFocused(true)}
                                        onBlur={() => setIsPasswordFocused(false)}
                                        aria-describedby="emailHelp"
                                        placeholder={t('ResetPassword.newPassword')} />
                                </div>

                                {isPasswordFocused && passErrors.length > 0 && (
                                    <div className="validation-messages">
                                        <ul>
                                            {passErrors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">{t('ResetPassword.newPasswordConfirm')}</label>
                                    <input type="password"
                                        className="form-control"
                                        id="confirmNewPassword"
                                        value={password_confirmation}
                                        required
                                        onChange={(e) => setPassword_confirmation(e.target.value)}
                                        placeholder={t('ResetPassword.newPasswordConfirm')}
                                    />
                                </div>

                                {Message &&
                                    <p className='Reset-errorMsg'>{Message}</p>
                                }

                                <button type="submit" className="btn btn-primary">{t('ResetPassword.confirmBtn')}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}

export default Rest_Password;
