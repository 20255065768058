// VideoContext.js
import { createContext, useContext, useState } from 'react';

const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
    const [selectedVideo, setSelectedVideo] = useState(null);

    return (
        <VideoContext.Provider value={{ selectedVideo, setSelectedVideo }}>
            {children}
        </VideoContext.Provider>
    );
};

export default function useVideoContext() {
    return useContext(VideoContext);
}