import { Route, Routes } from 'react-router-dom';
import React, { useEffect, useState, useMemo } from 'react';
import Home from './Components/Home/home';
import Profile from './Components/Profile/profile';
import EditProfile from './Components/EditProfile/EditProfile';
import Login from './Components/Login/Login';
import ResetPassword from './Components/Rest_Password/Rest_Password';
import SignUp from './Components/SignUp/SignUp';
import Contactus from './Components/ContactUS/contactus';
import Videos from './Components/Videos/videos';
import VideoDetails from './Components/Videos/VideoDetails'
import Verify from './Components/Verify/verify'
import AuthLayout from './layouts/AuthLayout';
import GuestLayout from './layouts/GuestLayout';
import ForgetPassword from './Components/Forget_Password/Forget_Password';
import NotFound404 from './Components/Error_Pages/NotFound404';
import PreLoader from './Components/PreLoader/PreLoader';
import { useTranslation } from "react-i18next";
import { getDirection } from './helpers/setupI18nLang';
import { VideoProvider } from './context/VideoContext';
import './App.scss';

function App() {
  const [loading, setLoading] = useState(true); //Loading state 
  const { i18n } = useTranslation(); // translation hook

  // Memo to get the direction  
  const dir = useMemo(() => {
    return getDirection();
  }, [i18n.language])

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  // loading and Clear Cache
  useEffect(() => {
    const dataFetch = () => {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
    dataFetch();
    clearCacheData();
  }, [])



  return (

    <>
      {loading ?

        (<PreLoader />)
        :
        <section dir={dir} className={`${i18n.language === 'ar' ? 'AR-Font' : 'EN-Font'}`}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Contact_Us" element={<Contactus />} />

            <Route element={<AuthLayout />}>

              <Route path='/Videos'
                element={
                  <VideoProvider>
                    <Videos />
                  </VideoProvider>
                }
              />
              <Route
                path='/VideoDetails'
                element={
                  <VideoProvider>
                    <VideoDetails />
                  </VideoProvider>
                }
              />

              <Route path="/profile" element={<Profile />} />
              <Route path="/EditProfile" element={<EditProfile />} />
            </Route>

            <Route element={<GuestLayout />}>
              <Route path="/SignUp" element={<SignUp />} />
              <Route path="/Verify/:phone" element={<Verify />}></Route>
              <Route path="/Login" element={<Login />} />
              <Route path="/Forget_Password" element={<ForgetPassword />} />
              <Route path="/user-password-reset/:phone" element={<ResetPassword />} />
            </Route>

            {/* Dynamic Route For Unknown Page */}
            <Route path="*" element={<NotFound404 />} />
          </Routes>
        </section >
      }
    </>

  );
}

export default App;
