import { useState } from 'react';
import Lock from '../../Static/images/Lock.webp'
import './Forget_Password.scss'
import axios from '../../api/axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom"
import SmPreLoader from '../PreLoader/SmPreLoader';
function Forget_Password() {

    const [Message, setMessage] = useState(); // state that manage the user Messages
    const [phone, setPhone] = useState(''); // email state
    const [C_Code, setc_Code] = useState('+02'); // C_CODE : Country Code
    const [t] = useTranslation(); // Translation hook
    const navigate = useNavigate(); // Navigation
    const [lod, setLod] = useState(false); // loading state


    // Function to handle the forget password
    const handleForgetPassword = async (event) => {
        event.preventDefault();
        try {
            setLod(true);
            setMessage('');
            if (phone === '') {
                setMessage(t('Forget_Password.phone_req'));
            }
            else {
                const response = await axios.post('forget', { phone });
                if (response.status === 200 && response.data[0] === 'send') {
                    setMessage('')
                    navigate(`/user-password-reset/${phone}`, { replace: true });
                }
                else if (response.status === 200 && response.data[0] === 'no user') {
                    setMessage(t('Forget_Password.phoneNotFound'))
                }
            }
        } catch (e) {
            setMessage('')
            if (e.response.data.message === 'Please wait before retrying.') {
                setMessage(t('ResetPassword.tooManyAttempts'));
            }
            else if (e.response.data.message === "We can't find a user with that email address.") {
                setMessage(t('ResetPassword.usNotExist'));
            }
            setMessage(e.response.data.message);
        }
        finally {
            setLod(false);
        }
    };

    return (
        // Reset Password ui
        <section className="Rest_Password">
            {/* Reset password Process Loader */}
            {lod && <SmPreLoader />}
            <div className='LoginDiv1'>
                <div>
                    <h4>
                        {t('Forget_Password.safety')}<br />
                        <img src={Lock} alt="lock" />
                    </h4>
                </div>
            </div>

            <div className='LoginDiv2'>
                <div className='LoginDiv2Child1'>
                    <div className='LoginDiv2Child1DV1'>
                        <h1>{t('Forget_Password.forgetHeader')}</h1>
                        <p>{t('Forget_Password.forgetDescription')}</p>

                        <form onSubmit={handleForgetPassword}>
                            <div className='resetpasswordContainer'>
                                <div className='countriesDV'>
                                    <select name="countries" aria-label="C_Code" aria-describedby='C_Code' id="countries" onChange={(e) => setc_Code(e.target.value)}>
                                        <option value="+02">
                                            EG (+02)
                                        </option>
                                    </select>
                                </div>

                                <div className='phoneNumberDV'>
                                    <input type="text"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        aria-describedby="phoneHelp"
                                        placeholder={t('Forget_Password.phonePlaceholder')}
                                        required
                                    />
                                </div>
                            </div>

                            {Message &&
                                // <div id='MessageDIV' className='ErrorMSG' >{<p>{Message}asd</p>}</div>
                                <p className='errorMsg'>{Message}</p>
                            }

                            <button type="submit" className="btn btn-primary" id='Forget_PasswordBTN'>{t('Forget_Password.sendBTN')}</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Forget_Password;
