import { useEffect, useState } from "react";

export default function useCount() {
    const [secondsLeft, setSecondsLeft] = useState(0);
    useEffect(() => {
        if (secondsLeft <= 0) return;
        const timeout = setTimeout(() => {
            setSecondsLeft(prev => prev - 1);
        }, 1000);

        return () => clearInterval(timeout);
    }, [secondsLeft])

    function start(seconds) {
        setSecondsLeft(seconds);
    }

    const formatTime = (time) => {
        let minutes = Math.floor(time / 60)
        let seconds = Math.floor(time - minutes * 60)

        if (minutes <= 10) minutes = '0' + minutes;
        if (seconds < 10) seconds = '0' + seconds;
        return minutes + ":" + seconds
    }
    return { secondsLeft, start, formatTime };
}