// Import necessary dependencies and styles
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import Nav from '../Navbar/Nav';
import Footer from './../Footer/footer';
import HandImage from '../../Static/images/3dHandIcons.webp';
import pcScreen from '../../Static/images/3dMonitoricons.webp';
import Coin from '../../Static/images/3dCoinIcons.webp';
import Calender from '../../Static/images/Calender.webp';
import Person from '../../Static/images/character/Character.webp';
import Save from '../../Static/images/SaveIcon.webp';
import { useTranslation } from 'react-i18next';
import './home.scss';
import "aos/dist/aos.css";
import "@fontsource/jost/400.css";

function Home() {
    // translation Hook
    const [t, i18n] = useTranslation();

    // Initialize AOS animation library
    useEffect(() => {
        Aos.init();
    }, []);

    // Return ui design
    return (
        <>
            {/* Navigation Bar */}
            <Nav />

            {/* Home content */}
            <section className='Home'>
                <div className="HomeSections">
                    {/* Section 1 */}
                    <div className={`HomeSec1 ${i18n.language === 'ar' ? 'HomeSec1AR' : ''}`}>
                        <div>
                            <p>{t('Home.S1Heading1')}</p>
                            <p>{t('Home.S1Heading2')}</p>
                            <p>{t('Home.S1Paragraph')}</p>
                            <div data-aos="rotate-up-left" className={`${i18n.language === 'ar' ? 'SignupAndContactAR' : ''}`}>
                                <button className='SignupBtn'>
                                    <Link to='/SignUp'>{t('Home.signupBTN')}</Link>
                                </button>
                                <button className='ContactBtn'>
                                    <Link to='/Contact_Us'>{t('Home.contactUsBTN')}</Link>
                                </button>
                            </div>
                        </div>
                        <div>
                            <img src={Person} alt="Person" width="420px" height="330px" effect='blur' />
                        </div>
                    </div>

                    {/* Section 2 */}
                    <div className="HomeSec2" data-aos="fade-right">
                        {/* Subsection 1 */}
                        <div className='HomeSec2Div1'>
                            <div>
                                <p>{t('Home.S1Heading3')}<br />{t('Home.S1Heading4')}</p>
                                <p>{t('Home.S1S2HeadingDesc')}</p>
                            </div>
                        </div>

                        {/* Subsection 2 */}
                        <div className='HomeSec2Div2'>
                            <div>
                                <img src={HandImage} alt="Hand" width="183px" height="183px" />
                                <h2>{t('Home.Create_Account')}</h2>
                            </div>
                            <div>
                                <img src={pcScreen} alt="PCScreen" width="183px" height="183px" />
                                <h2>{t('Home.Watch_Videos')}</h2>
                            </div>
                            <div>
                                <img src={Coin} alt="Coin" width="183px" height="183px" />
                                <h2>{t('Home.Earn_Money')}</h2>
                            </div>
                        </div>
                    </div>

                    {/* Section 3 */}
                    <div className="HomeSec3Container">
                        {/* Subsection 1 */}
                        <div className='HomeSec3' data-aos="fade-right">
                            <div className='HomeSec3Div1'>
                                <img src={Calender} alt="CalenderImg" width="500" height="500" />
                            </div>
                            <div className='HomeSec3Div2'>
                                <div>
                                    <p>{t('Home.calenderContent')}</p>
                                    <p>{t('Home.calenderContent2')}<br />{t('Home.calenderContentComp')}</p>
                                    <button className='SignupBtn'>
                                        <Link to='/SignUp'>{t('Home.signupBTN')}</Link>
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Subsection 2 */}
                        <div className='HomeSec3' data-aos="fade-right">
                            <div className='HomeSec3Div2'>
                                <div>
                                    <p>{t('Home.saveContent')}</p>
                                    <p>{t('Home.saveContentDesc')}</p>
                                    <button className='ContactBtn2'>
                                        <Link to='/Contact_Us'>{t('Home.contactUsBTN')}</Link>
                                    </button>
                                </div>
                            </div>
                            <div className='HomeSec3Div1' id='HomeSec3Div1ID'>
                                <img id='SaveIMg' src={Save} alt="saveImg" width="500" height="500" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Footer */}
            <Footer />
        </>
    );
}

export default Home;
