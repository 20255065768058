import React from 'react'
import './style.scss'
import Navbar from '../Navbar/Nav'
const error404 = () => {
  return (
    <>
      <Navbar />
      <section className='NotFound'>
        <div className='NotFoundContainer'>
          <h1>404</h1>
          <p>Page Not Found</p>
          <span>The page you are looking for in forgapay is not found</span>
        </div>
      </section>
    </>
  )
}

export default error404