import { createContext, useContext, useEffect, useState } from "react";
import axios from "../api/axios";
import enTranslations from "../locales/en/translation.json";
import arTranslations from "../locales/ar/translation.json";
import { useNavigate } from "react-router-dom";
import SmPreLoader from '../Components/PreLoader/PreLoader'
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const currentLanguage = localStorage.getItem('language');
  const translations = currentLanguage === 'en' ? enTranslations : arTranslations;

  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [Message, setMessage] = useState('');
  const [userToken, setUserToken] = useState(null);
  const [respErrors, setRespErrors] = useState([]);
  const [load, setLoad] = useState();

  // const csrf = () =>axios.get('/sanctum/csrf-cookie');

  const getUser = async (usToken) => {
    setLoad(true);
    try {
      const userData = axios.get('profile', {
        headers: { Authorization: `Bearer ${usToken}` }
      })
      const data = (await userData).data;
      setUser(data);
    }
    catch (e) {
      console.log(e);
    }
    finally {
      setLoad(false);
    }
  };

  const signUp = async ({ ...data }) => {
    try {
      const signUpResponse = await axios.post('register', data);
      if (signUpResponse.status === 200) {
        // Successful registration
        setTimeout(() => {
          setMessage('');
          navigate(`/Verify/${data.phone}`, { replace: true });
        }, 1000);
      }
    }
    catch (e) {
      console.log(e);
      if (e.response.status === 422) {
        setRespErrors(e.response.data.errors);
      }
    }
  }

  const login = async ({ ...data }) => {

    try {
      setMessage('');
      const MainData = await axios.post("login", data);
      const userFound = MainData.data.exist;
      const verifyState = MainData.data.verified;

      if (userFound) {
        if (verifyState != null) {
          await getUser(verifyState);
          setUserToken(verifyState);
          localStorage.setItem("userToken", verifyState);
          navigate("/", { replace: true });
        }
        else {
          navigate(`/Verify/${data.email}`, { replace: true });
        }
      } else {
        setRespErrors({ invalidLogin: translations.Login.invalidLoginError });
      }
    } catch (e) {
      console.log(e);
      if (e.response.status === 422) {
        setRespErrors(e.response.data.errors);
      }
    }
  };

  const logout = async () => {
    try {
      await axios.get('logout', {
        headers: { Authorization: `Bearer ${userToken}` }
      })
      localStorage.removeItem('userToken');
      setUser(null);
    }
    catch (e) {
      console.log(e);
    }
    finally {

    }


  }

  const contextDeleteAcc = async () => {
    localStorage.removeItem('userToken');
    setUser(null);
  }

  useEffect(() => {
    const storedToken = localStorage.getItem("userToken");
    if (storedToken) {
      // A token exists in localStorage, so you can consider the user as authenticated.
      try {
        setUserToken(storedToken);
        getUser(storedToken);
      }
      catch (e) {
        console.log(e);
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, Message, userToken, respErrors, getUser, login, signUp, logout, setUser, setMessage, contextDeleteAcc, setRespErrors }}>
      {load ? (<SmPreLoader />) : (children)}
    </AuthContext.Provider>
  );
};

export default function useAuthContext() {
  return useContext(AuthContext);
}
