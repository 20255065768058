export const setLang = (language) => {
    localStorage.setItem('language', language);
}

export const getLang = () => {
    let currentLang = localStorage.getItem('language');

    if (!currentLang) {
        currentLang = 'ar';
        setLang(currentLang);
    }
    return currentLang;
}

export const getDirection = (lang = null) => {
    let currentLang = lang || getLang();
    return currentLang === 'ar' ? 'rtl' : 'ltr';
}