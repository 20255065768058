import React, { useState } from 'react'; // react states
import Nav from '../Navbar/Nav';  // Navbar
import Footer from '../Footer/footer'; // Footer 
import { useTranslation } from 'react-i18next';  // translation hook
import SmPreLoader from '../PreLoader/SmPreLoader';
import './contactus.scss'   // scss file

const ContactUs = () => {

    // contact form input states hooks
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [textArea, setTextArea] = useState('');

    // Translation hook
    const [t] = useTranslation();

    // loading state
    const [lod, setLod] = useState(false);

    // Function used to send contacct details
    const handleContactUs = (event) => {
        event.preventDefault();
        try {
            setLod(true);
        } catch (e) {
            console.log(e)
        }
        finally {
            setLod(false);
        }
    }

    // Return contact_us ui
    return (
        <>
            {/* Login Process Loader */}
            {lod && <SmPreLoader />}
            {/* Navbar */}
            <Nav />

            {/* Contactus Section */}
            <section className='contactUs'>
                <div className='contact'>
                    <h1>{t('contactus.contactHeader')}</h1>
                    <div className='break'></div>
                    <div className='Content'>
                        <div className='maps'>
                            <iframe title='CB_Location'
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1726.1173307727322!2d31.345382942328847!3d30.087465071080935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583e0281c961eb%3A0x69dbf20302420ca!2zNjcg2KfZhNit2LHZitip2Iwg2KfZhNmF2KfYuNip2Iwg2YLYs9mFINmF2LXYsSDYp9mE2KzYr9mK2K_YqdiMINmF2K3Yp9mB2LjYqSDYp9mE2YLYp9mH2LHYqeKArCA0NDYxMTIy!5e0!3m2!1sar!2seg!4v1692627706115!5m2!1sar!2seg"
                                width="100%"
                                height="100%"
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>

                        <div className='contactDetails'>
                            <div className='formCont'>
                                <form onSubmit={handleContactUs}>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">{t('contactus.name')}</label>
                                        <input type="Name"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            aria-label='Name-Area'
                                            required
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">{t('contactus.email')}</label>
                                        <input type="email"
                                            className="form-control"
                                            aria-label='Email-Area'
                                            id="exampleFormControlInput1"
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">{t('contactus.phoneNumber')}</label>
                                        <input type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            required
                                            aria-label='phoneArea'
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlTextarea1">{t('contactus.Message')}</label>
                                        <textarea className="form-control inp-msg-area"
                                            required
                                            aria-label='messageArea'
                                            rows="4"
                                            value={textArea}
                                            onChange={(e) => setTextArea(e.target.value)}
                                        >

                                        </textarea>
                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-lg contactSubmit">{t('contactus.sendBtn')}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Footer */}
            <Footer />
        </>
    )
}

export default ContactUs