import React from 'react'; // Import React library
import './footer.scss'; // Import the CSS styles for the footer
import Logo from '../../Static/images/LOGO/ForgaLogo.webp'; // Import the logo image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesome icons
import { Link } from 'react-router-dom'; // Import Link component from React Router
import { faFacebook, faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons'; // Import social media icons
import { useTranslation } from 'react-i18next'; // translation

// Define the URLs for social media pages
const facebookPage = "https://www.facebook.com/forgapay/";
const instagramPage = "https://www.instagram.com/forgapay/"; // Change this to the correct Instagram URL
const tiktokPage = "https://www.tiktok.com/@forgapay"; // Change this to the correct LinkedIn URL


// Define the Footer component
function Footer() {
  const [t] = useTranslation(); // Translation Hook

  return (
    <footer className="Footer" id='FooterID'> {/* Start of footer container */}
      {/* Brand logo */}
      <div className='LogoContainer'>
        <img className='LogoImg' src={Logo} alt='ForgaPay Logo' /> {/* Display the logo */}
        <p>{t('footer.forgapayName')}</p>
      </div>

      <div className="Footbody">
        <div>
          {/* Horizontal line separator */}
          <div className='Line'></div>

          <div className='Icns'>
            <p>
              Copyright &copy; 2023 ForgaPay -
              powered by Creative Basket
            </p>
            <div>
              {/* Links to social media profiles */}
              <Link to={facebookPage} target="_blank" rel="noopener noreferrer" aria-label='facebook'><FontAwesomeIcon className='FotIcn' icon={faFacebook} /></Link> {/* Facebook icon */}
              <Link to={tiktokPage} target="_blank" rel="noopener noreferrer" aria-label='linkedin'><FontAwesomeIcon className='FotIcn' icon={faTiktok} /></Link> {/* LinkedIn icon */}
              <Link to={instagramPage} target="_blank" rel="noopener noreferrer" aria-label='instagram'><FontAwesomeIcon className='FotIcn' icon={faInstagram} /></Link> {/* Instagram icon */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

// Export the Footer component to use in other parts of the application
export default Footer;
