import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap"
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAuthContext from "../../context/AuthContext";
import SmPreLoader from '../PreLoader/SmPreLoader';
import './Login.scss';
import { useTranslation } from 'react-i18next';


function Login() {

    // inputs states fot login
    const [credential, setCredential] = useState('')
    const [password, setPassword] = useState("");
    const [lod, setLod] = useState(false); // loading state
    const [t] = useTranslation(); // translation hook calling
    const { login, respErrors, setRespErrors } = useAuthContext(); // calling (login , respErrors) from the Auth Context 

    // Function to handle login 
    const handleLogin = async (event) => {
        setRespErrors('');
        event.preventDefault();
        try {
            setLod(true);
            await login({ credential, password })
        }
        catch (e) {
            console.log(e);
        } finally {
            setLod(false);
        }
    };

    useEffect(() => {
        setRespErrors('');
    }, [])

    return (
        <>
            {/* Login Process Loader */}
            {lod && <SmPreLoader />}
            {/* Login UI */}
            <section className='Login'>
                <div className='LoginDiv1'>
                    {/* <img className="Logo" src={Logo} alt="..." /> */}
                    <div>

                        {/* <img src={im} width='500px' height='500px' alt="Forgapay" /> */}

                        <h1>
                            {t('Login.welcomeMSG')}

                        </h1>
                    </div>
                </div>

                <div className='LoginDiv2'>
                    <div className={'LoginDiv2Child1'} >
                        <div className='LoginDiv2Child1DV1'>
                            <h1>{t('Login.LoginPageHeader')}</h1>

                            <form onSubmit={handleLogin}>
                                <div className="form-group">
                                    <label htmlFor="emailOrPhoneinp">{t('Login.EmailLabel')}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="emailOrPhoneinp"
                                        aria-describedby="emailOrPhoneHelp"
                                        placeholder="e@ex.com || 01xxxxx"
                                        value={credential}
                                        required
                                        onChange={(e) => setCredential(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="passwordLabel" htmlFor="exampleInputPassword1">{t('Login.passwordLabel')}</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder={t('Login.passwordLabel')}
                                        value={password}
                                        required
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>

                                <div className="errorContainer">
                                    {respErrors.invalidLogin &&
                                        <small className='ErrorHelp'>{respErrors.invalidLogin}</small>
                                    }
                                </div>

                                <span>{t('Login.newAccQuestion')}<Link className='CreateAccBtn' to="/SignUp">{t('Login.signupBTN')}</Link></span>
                                <button type="submit" className="btn btn-primary">{t('Login.submitLogin')}</button>
                                <span><Link className='ForgetPasswordBtn' to="/Forget_Password" >{t('Login.passwordForget')}</Link></span>
                            </form>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default Login
