import React, { useEffect, useRef, useState } from 'react';
import axios from "../../api/axios";
import YouTube from 'react-youtube';
import Nav from '../Navbar/Nav';
import Footer from '../Footer/footer';
import './VideoDetails.scss';
import { useTranslation } from 'react-i18next';
import useAuthContext from "../../context/AuthContext";
import useVideoContext from '../../context/VideoContext';
import { useNavigate } from "react-router-dom";
import SmPreLoader from '../PreLoader/SmPreLoader';

const VideoDetails = () => {
    const navigate = useNavigate();

    const [lod, setLod] = useState(false); // loading state
    const { selectedVideo } = useVideoContext();
    const selectedvideoID = selectedVideo ? selectedVideo.id.videoId : null;
    const [viewDuration, setViewDuration] = useState(null);
    const [realDuration, setRealDuration] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [earnMSG, setEarnMSG] = useState(false);
    const playerRef = useRef(null);
    const countdownIntervalRef = useRef(null);
    const [t] = useTranslation();

    //Get user Token From Context
    const { userToken } = useAuthContext(); // Get the user Token from the Auth Context 

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
            controls: 0,
            disablekb: 1,
            enablejsapi: 1,
            showAds: 1,
        },
    };

    // Convert the total watching time for the user
    const convertTime = (sec) => {
        const minutes = (sec / 60);
        return minutes
    };

    const sendViewDuration = async (vID, Duration) => {
        try {
            setLod(true);
            const durationResponse = await axios.post('store',
                { videoID: vID, minutes: Duration },
                { headers: { Authorization: `Bearer ${userToken}` } })
            if (durationResponse.data.message === 'Stored') {
                navigate(`/Videos`);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setLod(false);
        }
    }

    // When the video is ready
    const onReady = (event) => {
        playerRef.current = event.target;
        setViewDuration(event.target.getDuration() - 1);
        setRealDuration(event.target.getDuration());

    };

    // When the video state is changed
    const onStateChange = (event) => {
        const videoState = event.data;
        if (videoState === YouTube.PlayerState.PLAYING) {
            document.title = t('videoDetails.videoPlay');
            setIsPlaying(true);
        }
        else if (videoState === YouTube.PlayerState.PAUSED) {
            document.title = t('videoDetails.videoPause');
            setIsPlaying(false);
        }
        else if (videoState === YouTube.PlayerState.ENDED) {
            setIsPlaying(false);
            sendViewDuration(selectedvideoID, convertTime(realDuration - viewDuration));
        }
        else {
            setIsPlaying(false);
        }
    };

    // Detect Video availability
    useEffect(() => {
        if (selectedvideoID === null) {
            navigate(`/Videos`, { replace: true });
        }
    }, [])

    // start to count down according to the video state
    useEffect(() => {
        if (isPlaying && !earnMSG) {
            countdownIntervalRef.current = setInterval(() => {
                setViewDuration((prevDuration) => prevDuration - 1);
            }, 1000);
        } else {
            clearInterval(countdownIntervalRef.current);
        }
    }, [isPlaying]);

    // Detect that the user view duration reaches zero (0)
    useEffect(() => {
        if (viewDuration === 0) {
            setIsPlaying(false);
            setEarnMSG(true);
        }
    }, [viewDuration]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden && playerRef.current) {
                // Page is hidden, pause the video
                playerRef.current.pauseVideo();
                document.title = ('video is paused');
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            clearInterval(countdownIntervalRef.current);
        };
    }, [isPlaying]);


    return (
        <>
            {/* Login Process Loader */}
            {lod && <SmPreLoader />}
            <Nav />
            <div className='Warnings'>
                <h4>{t('videoDetails.warning')}</h4>
                <ul>
                    <li>{t('videoDetails.refreshIssue')}</li>
                    <li>{t('videoDetails.pauseIssue')}</li>
                </ul>
            </div>

            <div className="video">
                <div className='watchDetails'>
                    {isPlaying ?
                        (<p className='seconds'>{viewDuration} {t('videoDetails.seconds')}</p>)
                        :
                        (<p className='seconds'>{t('videoDetails.playVideo')}</p>)
                    }
                </div>

                <div className='videoContainer'>
                    <YouTube className='youtubeVideo' videoId={selectedvideoID} opts={opts} onReady={onReady} onStateChange={onStateChange} />
                </div>
            </div>
            <Footer />

        </>
    );
}

export default VideoDetails;
