import React, { useState } from 'react';
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import useAuthContext from "../../context/AuthContext";
import SmLoader from '../PreLoader/SmPreLoader';
import Logo from '../../Static/images/LOGO/ForgaLogo.webp';
import './Nav.scss';

function Navbar() {

  // Hooks Calling
  const location = useLocation();  // Get current location Hook  
  const { user, logout } = useAuthContext();  // Get user and logout Hook
  const [t, i18n] = useTranslation(); // Translation Hook
  const [lod, setLod] = useState(false);

  const handleLogout = async () => {
    try {
      setLod(true);
      await logout();
    }
    catch (e) {
      console.log(e);
    }
    finally {
      setLod(false);
    }
  }

  return (
    <>
      {/* Loading process for logout */}
      {lod && <SmLoader />}
      {/* Navbar Content */}
      <nav className={`navbar navbar-expand-lg ${i18n.language === 'en' ? 'navbar-EnLang' : ''}`}>
        <div className="container-fluid">
          <div className='navBarBrandDV'>
            <img className="navbar-brand" src={Logo} alt='Forgapay_Logo' />
            <p className='forgaName'>{t('Navbar.forgapayName')}</p>
          </div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul id='LinUL' className="navbar-nav me-auto mb-2 mb-lg-0">
              {/* Navigation links */}
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/">{t('Navbar.NavHome')}</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname.includes('/videos') ? 'active' : ''}`} to="/videos">{t('Navbar.NavVideo')}</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/Contact_Us' ? 'active' : ''}`} to="/Contact_Us">{t('Navbar.Contactus')}</Link>
              </li>
              {!user &&
                < li className="nav-item">
                  <Link className={`nav-link ${location.pathname === '/Login' ? 'active' : ''}`} to="/Login">{t('Navbar.Login')}</Link>
                </li>
              }

              {/* Dropdown for user profile */}
              {user &&
                <li className="nav-item">
                  <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {/* {`${user?(user.user.email):(translations.Navbar.Username)}`} */}
                      {`${user ? user.name : (t('Navbar.Username'))}`}

                    </button>
                    <ul className="dropdown-menu">
                      <li><Link to='/Profile'><button className="dropdown-item profileBtn" type="button">{t('Navbar.Profile')}</button></Link></li>
                      <li><button className="dropdown-item profileBtn" type="button" onClick={handleLogout}>{t('Navbar.Logout')}</button></li>
                    </ul>
                  </div>
                </li>
              }
              {/* Language toggle */}
              <li className="nav-item globIcn">

                {i18n.language === 'en' &&
                  <button className='langBtn' onClick={() => {
                    i18n.changeLanguage('ar');
                    localStorage.setItem('language', 'ar');
                  }}>
                    {t('lng.arabic')}
                    <FontAwesomeIcon icon={faGlobe} className='faGlobeIcn' />
                  </button>
                }

                {i18n.language === 'ar' &&
                  <button className='langBtn' onClick={() => {
                    i18n.changeLanguage('en');
                    localStorage.setItem('language', 'en');
                  }}>
                    <FontAwesomeIcon icon={faGlobe} className='faGlobeIcn' />
                    {t('lng.english')}
                  </button>
                }
              </li>
            </ul>
          </div>
        </div>
      </nav >
    </>
  );
}

export default Navbar;
