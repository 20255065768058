// Preloader.js
import React from 'react';
import './PreLoader.scss'; // Import your SCSS file


function PreLoader() {
  return (
    <div className="preloader">
      
    </div>
  );
};

export default PreLoader;
