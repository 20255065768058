import React from 'react';
import { useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import './videos.scss';
import Nav from '../../Components/Navbar/Nav';
import { useTranslation } from 'react-i18next';
import search from '../../Static/images/SearchBar.webp';
import Footer from './../Footer/footer';
import { Link } from 'react-router-dom';
import axios from '../../api/axios';
import useAuthContext from "../../context/AuthContext";
import useVideoContext from "../../context/VideoContext";
import Aos from 'aos';
import "aos/dist/aos.css";

function Videos() {
  const { selectedVideo, setSelectedVideo } = useVideoContext();

  const [t] = useTranslation();

  const addVideoToContext = (video) => {
    try {
      setSelectedVideo(video);
    }
    catch (e) {
      console.log(e);
    }
  }

  // get json data
  const [jsonData, setJsonData] = useState(null);

  //Get user Token From Context
  const { userToken } = useAuthContext(); // Get the user Token from the Auth Context 


  // State for search input value
  const [searchValue, setSearchValue] = useState('');

  // Function to handle channel selection
  const ChooseChannel = (event) => {
    event.preventDefault();
    const clickedButtonValue = event.target.value;
    console.log(clickedButtonValue);
  };

  // Initialize Aos library for animations on component mount
  useEffect(() => {
    async function fetchVideos() {
      try {
        const videoResp = await axios.get('videos', {
          headers: { Authorization: `Bearer ${userToken}` }
        })
        setJsonData(videoResp.data.items)
      }
      catch (e) {
        console.log(e);
      }
    }

    fetchVideos();
    Aos.init();
  }, []);


  // Update search input value when user types
  const handleInputSearch = (event) => {
    setSearchValue(event.target.value);
  };

  // Handle search button click
  const handleSearchClick = (event) => {
    event.preventDefault();
    console.log('Search value:', searchValue);
    setSearchValue('');
  };

  return (
    <>
      <Nav />
      <section className="Videos">
        <div className='SearchDiv'>
          <div>
            {/* Search form */}
            <form className="d-flex" role="search">
              <input disabled className="form-control me-1 search-bar" type="search" placeholder="Search" aria-label="Search" onChange={handleInputSearch} value={searchValue} />
              <button disabled className="btn btn-outline-success" type="submit"><img src={search} alt="..." onClick={handleSearchClick} /></button>
            </form>

            <div className='SeriousNav'>
              {/* Channel selection buttons */}
              <form className='viewSelection' onClick={ChooseChannel}>
                <button value="Films Home" disabled>Films Home</button>
                <button value="Films Home Plus" disabled>Films Home plus </button>
                <button value="Serious Home" disabled>CB Serious</button>
              </form>
            </div>
          </div>
        </div>

        <div className="videosDiv2">
          <div className="videosContainer" data-aos="fade-right">
            {/* Mapping over videosData to display videos */}

            {jsonData ? (
              jsonData.map((video) => (
                <Link to={`/VideoDetails`} onClick={() => addVideoToContext(video)} key={video.id.videoId} className="Vid">
                  <div>
                    <img
                      src={video.snippet.thumbnails.high.url}
                      width="100%"
                      height="90%"
                      alt="..."
                    />
                  </div>
                  <p>{video.snippet.title}</p>
                </Link>
              ))
            ) : <p className='NoVideosFound'>{t('videos.NoVideos')}</p>}
          </div>
        </div>
      </section>
      <Footer />
    </>

  );
};

export default Videos;
