import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CashFlow from './../../Static/images/CashFlow.webp';
import './SignUp.scss';
import useAuthContext from "../../context/AuthContext";
import { usePasswordValidation } from '../../helpers/usePasswordValidation';
import { useTranslation } from 'react-i18next';
import SmPreLoader from '../PreLoader/SmPreLoader';

const SignUp = () => {
    // input states for signup
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [passsword_confirmation, setPasssword_Confirmation] = useState('');
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const { signUp, respErrors, setRespErrors } = useAuthContext(); // call from Auth Context the (signup , respErrors and setRespErrors)
    const { password, setPassword, passErrors } = usePasswordValidation(); // call password validation hooks
    const [t, i18n] = useTranslation(); // call the translation hook
    const [lod, setLod] = useState(false); // loading state

    // Effect of set errors empty at component loading
    useEffect(() => {
        setRespErrors([]);
    }, []);


    // Function to handle form submission
    const handleSignup = async (event) => {
        event.preventDefault();
        try {
            setLod(true);
            if (passErrors.length === 0) {
                if (password === passsword_confirmation) {
                    if (phone.length >= 11) {
                        setRespErrors([]);
                        await signUp({ name, phone, email, password });
                    }
                    else {
                        setRespErrors({ phone: ["", t('Signup.CompletePhoneNum')] });
                    }
                }
                else {
                    setRespErrors({ notmatch: t('Signup.passNotmatch') });
                }
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setLod(false);
        }
    };
    // If not loading, render the SignUp component
    return (
        <>
            {lod && <SmPreLoader />}
            <section className='SignUp'>
                <div className='LoginDiv1'>
                    {/* Brand logo */}
                    {/* <img width='120px' height='70px' src={Logo} alt="..." /> */}
                    <div>
                        <h4>
                            {/* Information about watching videos to earn money */}
                            {t('Signup.WatchToEarn')}<br />
                            <img className='cashFlowICN' src={CashFlow} alt="CashFlow" />
                        </h4>
                    </div>
                </div>

                <div className='LoginDiv2'>
                    <div className='LoginDiv2Child1'>
                        <div className='LoginDiv2Child1DV1'>
                            <h1>{t('Signup.Create_Account')}</h1>
                            {/* Form for user registration */}
                            <form onSubmit={handleSignup}>

                                <div className="form-group">
                                    <label htmlFor="NameInp">{t('Signup.user_name')}</label>
                                    <input type="text"
                                        className="form-control"
                                        name='NameInp'
                                        aria-label='NameInput'
                                        required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="PhoneInp" >{t('Signup.userPhoneNumber')}</label>
                                    <input type="text"
                                        className="form-control"
                                        name='PhoneInp'
                                        aria-label='phoneInput'
                                        required
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    {respErrors.phone &&
                                        <>
                                            {respErrors.phone[0] &&
                                                // <small className='ErrorHelp'>{respErrors.phone[0]}</small>
                                                <small className='ErrorHelp'>{t('Signup.phoneExist')}</small>
                                            }
                                            {respErrors.phone[1] &&
                                                <small className='ErrorHelp'>{respErrors.phone[1]}</small>
                                            }

                                        </>
                                    }


                                </div>

                                <div className="form-group">
                                    <label htmlFor="EmailInp">{t('Signup.user_email')}</label>
                                    <div>
                                        <input type="email"
                                            className="form-control"
                                            name='EmailInp'
                                            aria-label='EmailInput'
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    {respErrors.email &&
                                        <small className='ErrorHelp'>{t('Signup.emailExist')}</small>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="passInp">{t('Signup.user_password')}</label>
                                    <div>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name='passInp'
                                            aria-label='PasswordInput'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onFocus={() => setIsPasswordFocused(true)}
                                            onBlur={() => setIsPasswordFocused(false)}
                                            required
                                        />

                                        {isPasswordFocused && passErrors.length > 0 && (
                                            <div className="validation-messages">
                                                <ul>
                                                    {passErrors.map((error, index) => (
                                                        <li key={index}>{error}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="CPassInp">{t('Signup.user_ConfPassword')}</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        name='CPassInp'
                                        aria-label='passwordConfirmationInput'
                                        value={passsword_confirmation}
                                        onChange={(e) => setPasssword_Confirmation(e.target.value)}
                                        required
                                    />
                                    {respErrors.notmatch &&
                                        <small className='ErrorHelp'>{t('Signup.passNotmatch')}</small>
                                    }
                                </div>

                                <div className="signupBtnContainer">
                                    <button type="submit" className="btn btn-primary">{t('Signup.createAccBtn')}</button>
                                </div>
                            </form>
                            {/* Login and social sign-up options */}
                            <div className='signup-LoginControl'>
                                <p>{t('Signup.accountExist')} <Link to='/Login'>{t('Signup.loginBtn')}</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SignUp;
