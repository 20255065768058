import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function usePasswordValidation() {
    const [password, setPassword] = useState('');
    const minLength = 8;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /[0-9]/;
    const specialCharacterRegex = /[!@#$%^&*]/;
    const passErrors = [];
    const [t] = useTranslation();

    if (password.length < minLength) {
        passErrors.push(t('passwordValidation.passwordLong'));
    }

    if (!uppercaseRegex.test(password)) {
        passErrors.push(t('passwordValidation.passwordUpperCase'));
    }

    if (!lowercaseRegex.test(password)) {
        passErrors.push(t('passwordValidation.passwordLowerCase'));
    }

    if (!digitRegex.test(password)) {
        passErrors.push(t('passwordValidation.passwordDigit'));
    }

    if (!specialCharacterRegex.test(password)) {
        passErrors.push(t('passwordValidation.passwordSpecialChar'));
    }

    return { password, setPassword, passErrors };
}
