import React, { useState, useEffect } from 'react';
import Nav from '../Navbar/Nav'
import Footer from '../Footer/footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import useAuthContext from '../../context/AuthContext';
import { usePasswordValidation } from '../../helpers/usePasswordValidation';
import { useTranslation } from 'react-i18next';
import axios from '../../api/axios';
import './EditProfile.scss'
import Aos from 'aos';
import "aos/dist/aos.css";
import SmPreLoader from '../PreLoader/SmPreLoader';

function EditProfile() {
  // Determine translation based on selected language in local storage
  const { user, userToken, contextDeleteAcc } = useAuthContext(); // call form Authcontext hooks of (user,userToken,contextDeleteAcc)
  const { password, setPassword, passErrors } = usePasswordValidation(); // call password validation hooks
  // State to control visibility of delete confirmation dialog
  const [showMainDiv, setShowMainDiv] = useState(false);
  // Edit Name
  const [newName, setNewName] = useState('');
  // Edit Password
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  // Edit Phone Number
  const [newPhone, setNewPhone] = useState('');
  const [userOrigPassword, setUserOrigPassword] = useState('');
  // Message
  const [passwordMessage, setPasswordMessage] = useState(null);
  const [numberMessage, setNumberMessage] = useState(null);
  // Translation Hook
  const [t] = useTranslation(); // Calling the translation Hook

  // set loading state
  const [lod, setLod] = useState(false);

  // Initialize AOS library for animations
  useEffect(() => {
    Aos.init();
  }, []);


  // Function to handle the change in the user name
  const handleChangeName = async (event) => {
    event.preventDefault();
    try {
      setLod(true);
      await axios.put('editName', { name: newName }, { headers: { Authorization: `Bearer ${userToken}` } });
    }
    catch (e) {
      console.log(e);
    }
    finally {
      setLod(false);
      setNewName('');
      window.location.reload();
    }
  }

  // Function to handle the changing of the password
  const handleChangePassword = async (event) => {
    event.preventDefault();
    try {
      setLod(true);
      if (passErrors.length === 0) {
        if (password === confirmNewPassword) {
          setPasswordMessage(() => []);
          const changePasswordResp = await axios.put('editPass', { old_password: currentPassword, new_password: password }, { headers: { Authorization: `Bearer ${userToken}` } });
          if (changePasswordResp.data.result === "Wrong Password") {
            setPasswordMessage(() => [t('EditProfile.invalidPassword'), 'errorMSG']);
          }
          else if (changePasswordResp.data.result === 'Done') {
            setPasswordMessage(() => [t('EditProfile.passChanged'), 'successMSG']);
          }
        }
        else {
          setPasswordMessage(() => [t('EditProfile.passNotmatch'), 'errorMSG']);
        }

      }
      else {
        setPasswordMessage(() => [t('EditProfile.CompleteValidations'), 'errorMSG']);
      }
    }
    catch (e) {
      console.log(e);
    }
    finally {
      setLod(false);
      setCurrentPassword('');
      setPassword('');
      setConfirmNewPassword('');
    }
  }

  // Function to handle the number changing
  const handleChangeNumber = async (event) => {
    event.preventDefault();
    try {
      setLod(true);
      const changeNumberResp = await axios.put('editPhone', { phone: newPhone, password: userOrigPassword }, { headers: { Authorization: `Bearer ${userToken}` } });
      if (changeNumberResp.data.result === "Done") {
        setNumberMessage(() => [t('EditProfile.passChanged'), 'successMSG']);
        window.location.reload();
      }
      else if (changeNumberResp.data.result === "Wrong Password") {
        setNumberMessage(() => [t('EditProfile.invalidPassword'), 'errorMSG']);
      }
    }
    catch (e) {
      console.log(e);
    }
    finally {
      setLod(false);
      setNewPhone('');
      setUserOrigPassword('');
    }
  }


  // Function handle the warning before confirm delete
  const handleDeleteButtonClick = async () => {
    try {
      setShowMainDiv(true);
    }
    catch (e) {
      console.log(e);
    }
  };

  // Function to handle the cancel button to canel the warning message
  const handleCancelDeleteClick = async () => {
    setShowMainDiv(false);
  };

  // Function to handle confirm account delete
  const handleConfirmDelete = async (e) => {
    e.preventDefault();
    setLod(true);
    try {
      const deleteResp = await axios.get('editDelete', { headers: { Authorization: `Bearer ${userToken}` } });
      contextDeleteAcc();
    }
    catch (e) {
      console.log(e);
    }
    finally {
      setLod(false);
    }
  }

  return (
    <>
      {lod && <SmPreLoader />}
      {/* Navigation */}
      <Nav />
      <section className="EditProfile">

        <div className="Container">

          {/* Edit Name Section */}
          <div className='userDataEdit' data-aos="fade-right">
            <div className='Div_Title'><p>{t('EditProfile.nameHeader')}</p></div>
            <div className='EditNameDiv2'>
              <form onSubmit={handleChangeName}>
                {/* Current Name */}
                <div className="form-group row">
                  <label htmlFor="inputName3" className="col-sm-3 col-form-label">{t('EditProfile.currentName')}</label>
                  <div className="col-sm-6 Contain_input">
                    <input type="text"
                      className="form-control"
                      id="inputName3"
                      // value={user.name}
                      aria-label='Username'
                      aria-hidden="true"
                      disabled
                    />
                  </div>
                </div>
                {/* New Name */}
                <div className="form-group row">
                  <label htmlFor="inputNewName" className="col-sm-3 col-form-label">{t('EditProfile.newName')}</label>
                  <div className="col-sm-6">
                    <input type="text"
                      required
                      value={newName}
                      id='inputNewName'
                      className="form-control"
                      onChange={(e) => setNewName(e.target.value)}
                    />
                  </div>
                </div>
                {/* Confirm Name Update */}
                <div className="form-group row">
                  <div>
                    <button type="submit"
                      className="btn btn-primary"
                    >
                      {t('EditProfile.confirmUpdateBtn')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* Edit Password Section */}
          <div className='userDataEdit' data-aos="fade-right">
            <div className='Div_Title'><p>{t('EditProfile.passwordHeader')}</p></div>
            <div className='EditNameDiv2'>
              <form onSubmit={handleChangePassword}>
                {/* Current Password */}
                <div className="form-group row">
                  <label htmlFor="inputPassword3" className="col-sm-3 col-form-label">{t('EditProfile.currentPassword')}</label>
                  <div className="col-sm-6 Contain_input">
                    <input type="password"
                      required
                      value={currentPassword}
                      className="form-control"
                      id="inputPassword3"
                      autoComplete="current-password"
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </div>

                </div>
                {/* New Password */}
                <div className="form-group row">
                  <label htmlFor="inputNewPassword" className="col-sm-3 col-form-label">{t('EditProfile.newPassword')}</label>
                  <div className="col-sm-6">
                    <input type="password"
                      required
                      value={password}
                      id='inputNewPassword'
                      className="form-control"
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={() => setIsPasswordFocused(true)}
                      onBlur={() => setIsPasswordFocused(false)}
                    />
                  </div>
                </div>

                {isPasswordFocused && passErrors.length > 0 && (
                  <div className="validation-messages">
                    <ul>
                      {passErrors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </div>
                )}


                {/* Confirm New Password */}
                <div className="form-group row">
                  <label htmlFor="inputConfirmPassword" className="col-sm-3 col-form-label">{t('EditProfile.confirmNewPassword')}</label>
                  <div className="col-sm-6">
                    <input type="password"
                      required
                      value={confirmNewPassword}
                      id='inputConfirmPassword'
                      className="form-control"
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                  </div>
                </div>

                {passwordMessage &&
                  <div className={passwordMessage[1]}>{<p>{passwordMessage[0]}</p>}</div>
                }

                {/* Confirm Password Update */}
                <div className="form-group row">
                  <div>
                    <button type="submit"
                      className="btn btn-primary"
                    >
                      {t('EditProfile.confirmUpdateBtn')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/*Update Phone Number Section */}
          <div className='userDataEdit' data-aos="fade-right">
            <div className='Div_Title'><p>{t('EditProfile.PhoneHeader')}</p></div>
            <div className='EditNameDiv2'>

              <form onSubmit={handleChangeNumber}>
                {/* New Phone Number */}
                <div className="form-group row">
                  <label htmlFor="inputNewPhone" className="col-sm-3 col-form-label">{t('EditProfile.newPhoneNumber')}</label>
                  <div className="col-sm-6">
                    <input type="text"
                      required
                      value={newPhone}
                      id='inputNewPhone'
                      className="form-control"
                      onChange={(e) => setNewPhone(e.target.value)}
                    />
                  </div>
                </div>

                {/* Password */}
                <div className="form-group row">
                  <label htmlFor="password" className="col-sm-3 col-form-label">{t('EditProfile.password')}</label>
                  <div className="col-sm-6">
                    <input type="password"
                      required
                      value={userOrigPassword}
                      className="form-control"
                      id="password"
                      onChange={(e) => setUserOrigPassword(e.target.value)}
                    />
                  </div>
                </div>
                {numberMessage &&
                  <div className={numberMessage[1]}>{<p>{numberMessage[0]}</p>}</div>
                }

                {/* Confirm Phone Number Update */}
                <div className="form-group row">
                  <div>
                    <button type="submit"
                      className="btn btn-primary"
                    >
                      {t('EditProfile.confirmUpdateBtn')}
                    </button>
                  </div>
                </div>
              </form>

            </div>
          </div>

          {/* Delete Account Section */}
          <div className='userDataEdit' data-aos="fade-right">
            <div className='Div_Title'><p>{t('EditProfile.deleteheader')}</p></div>
            <div className='EditNameDiv2'>
              <form>
                <div className="form-group deleteAccDV">
                  <div>
                    {/* Button to trigger delete confirmation */}
                    <button type="button" className="btn btn-primary del-btn" onClick={handleDeleteButtonClick}>
                      {t('EditProfile.deleteAccount')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {showMainDiv && (
            // Delete confirmation dialog
            <div className="ConfirmDelete">
              <div className="ConfirmDeleteDiv1">
                <FontAwesomeIcon className="WarningIcn" icon={faTriangleExclamation} />
                <div className="ConfirmDeleteDiv1Child1">
                  <h3>{t('EditProfile.deleteAlertContent')}</h3>
                </div>
                <div className="ConfirmDeleteDiv1Child2">
                  <form>
                    <button className="ConfirmDeleteBtn" onClick={handleConfirmDelete}>
                      {t('EditProfile.alertContinue')}
                    </button>

                    <button onClick={handleCancelDeleteClick} className="CancelDeleteBtn">
                      {t('EditProfile.alertCancel')}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}

        </div>
        <Footer />
      </section>
    </>
  );
}

export default EditProfile;
