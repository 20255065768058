// Import necessary modules and components
import React, { useState } from 'react';
import Nav from '../Navbar/Nav';
import Footer from '../Footer/footer';
import './profile.scss';
import User from '../../Static/images/User.webp';
import { Link } from 'react-router-dom';
import useAuthContext from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import axios from '../../api/axios';
import SmPreLoader from '../PreLoader/SmPreLoader';

function Profile() {
  // Hooks Calling
  const { user, userToken } = useAuthContext(); // get (user data , userToken and setuser) from AuthContext  
  const [t] = useTranslation(); // Translation Hook
  const [userBalance, setUserBalance] = useState('***');

  const [showNotify, setShowNotify] = useState(false);

  // set loading state
  const [lod, setLod] = useState(false);


  const closeNotify = () => {
    setShowNotify(false);
  }

  // Function to handle the change in the user name
  const getBalance = async (event) => {
    try {
      setLod(true);
      const balanceResp = await axios.get('wallet', { headers: { Authorization: `Bearer ${userToken}` } });
      setUserBalance(balanceResp.data.wallet)
    }
    catch (e) {
      console.log(e);
    }
    finally {
      setLod(false);
    }
  }

  // Function to handle the change in the user name
  const withdraw = async (event) => {
    try {
      setLod(true);
      const paymentResponse = await axios.post('payment', {}, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      });
      if (paymentResponse.data.message === 'Transaction is pending') {
        setShowNotify(true);
      }
    }
    catch (e) {
      console.log(e);
    }
    finally {
      setLod(false);
    }
  }

  return (
    <>
      {lod && <SmPreLoader />}
      {/* Render the navigation bar */}
      <Nav />
      <section className="profile">
        <div className='profileMain'>
          {/* Section to display user's earnings */}
          <div className='userEarns'>
            <h3>{t('profile.EarnsHeader')}</h3>
            <hr />
            {/* Replace 'Money$' with actual earnings */}
            <div className='earningContainer'>
              <p className='totalEarn'>{t('profile.totalEarns')} : {userBalance}</p>
              <button className='showBalance' onClick={getBalance}>{t('profile.showBalance')}</button>
              <button className='withdrowCash' onClick={withdraw}>{t('profile.withDraw')}</button>
            </div>
          </div>

          {/* Section to display user's profile information */}
          <div className='profileInfo'>
            <div className='profileInfoDv1'>
              {/* Display user avatar and username */}
              <div className='UsName'>
                <img width="130px" height="130px" src={User} alt="User Avatar" />
                <h1>{user.name}</h1>
              </div>
              {/* Display user's phone number and email */}
              <div className='UsNum_Email'>
                <p>{t('profile.phoneNum')} : {user.phone}</p>
              </div>
            </div>

            {/* Create a link to edit profile, utilizing localized content */}
            <Link className={'nav-link EditBtnWord EditBtn'} to="/EditProfile">
              {t('profile.editBtn')}
            </Link>
          </div>

          {showNotify &&
            <div className='withdrawNotify'>
              <div className='notification'>
                <h1 className='withdrawHeader'>
                  {t('profile.withdrawNotifyHeader')}
                </h1>
                <p className='withdrawP'>
                  {t('profile.withdrawNotifysucessRecieve')}
                </p>
                <p className='withdrawP'>
                  {t('profile.withdrawNotifyTeamCall')}
                </p>
                <button className='withdrawNotifyClose' onClick={closeNotify}>{t('profile.agreeBtn')}</button>
              </div>
            </div>
          }
        </div>


      </section>
      {/* Render the footer */}
      <Footer />
    </>
  );
}

// Export the Profile component as the default export
export default Profile;
