import './verify.scss'
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from '../../api/axios';
import OtpInput from 'react-otp-input';
import { useTranslation } from 'react-i18next';
import SmPreLoader from '../PreLoader/SmPreLoader';
import { useState } from 'react';
import useCount from '../../helpers/useCountDownTimer';


function Verify() {
  const { phone } = useParams();
  const [message, setMessage] = useState();
  const [otp, setOtp] = useState('');
  const [lod, setLod] = useState(false); // loading state
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { secondsLeft, start, formatTime } = useCount();

  useEffect(() => {
    start(50); // Assuming 90 is the initial value for your countdown
  }, []);

  const handleResend = async (event) => {
    event.preventDefault();
    try {
      setLod(true);
      const response = await axios.post('resend', { params: { phone } });
    } catch (e) {
      console.log(e);
    }
    finally {
      setLod(false);
      start(5);
    }
  }

  const handleVerify = async (event) => {
    event.preventDefault();

    try {
      setLod(true);
      const verifyResponse = await axios.post('verify', { otp })
      if (verifyResponse.data === "Verified") {
        navigate("/login", { replace: true });
      } else if (verifyResponse.data === 'OTP expired or not found.') {
        setMessage("OTP Expired")
      }
      else {
        navigate("/login", { replace: true });
      }
    }
    catch (e) {
      console.log(e);
    }
    finally {
      setLod(false);
    }
  }

  return (
    <>
      {lod && <SmPreLoader />}
      <section className='Verify'>
        <div className='verifyContainer'>

          <div className="card">

            <div className="card-header">
              <h5>{t('Verify.accVerifyHeader')}</h5>
            </div>

            <div className="card-body">

              <h6 className="card-title">{t('Verify.accVerifyDescription')}</h6>
              <hr />

              <div className='card-data'>
                <div className='card-Data-Resend'>
                  <p className='verification-label'>{t('Verify.verificationCodeTitle')}</p>

                  <div className='resendControl'>
                    {secondsLeft === 0 ?
                      <button className='verification-resend'
                        onClick={handleResend}
                      >
                        {t('Verify.resendButton')}
                      </button>
                      :
                      <span className='count-down'>{t('Verify.resendTime')} ({formatTime(secondsLeft)})</span>
                    }
                  </div>
                </div>

                <div className='card-Data-otp'>
                  {/* https://www.npmjs.com/package/react-otp-input */}
                  <OtpInput
                    value={otp.toString()}
                    onChange={setOtp}
                    numInputs={6}
                    placeholder='123456'
                    containerStyle='inputsContainerCustomStyle'
                    inputStyle='inputCustomStyle'
                    skipDefaultStyles={true}
                    renderInput={(props) => <input {...props} />}
                  />

                  {/* <input type='submit' className="btn btn-primary" onClick={handleVerify} value={t('Verify.resendBtn')} /> */}
                  <input type='submit' className="btn btn-primary verifyOTP-BTN" onClick={handleVerify} value={t('Verify.confirmBtn')} />

                  {message &&
                    <div className='ErrorMSG'>{<p className='errorMSGContent'>{message}</p>}</div>
                  }
                  <Link className='verifyProblem' to='/Contact_Us'>{t('Verify.problem')}</Link>
                </div>

              </div>
            </div>


          </div>
        </div>
      </section>
    </>
  )
}

export default Verify